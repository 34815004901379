<template>
  <div>
    <NavBar :content="site.NavBar.props.content" v-if="site.NavBar.props.content"/>
      <router-view></router-view>
    <FooterBar :content="site.FooterBar.props.content" v-if="site.NavBar.props.content"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      site:{
        NavBar:{props:{}},
        FooterBar:{props:{}}
      }
    };
  },
  methods:{
    async init(){
      const response = await import("./siteJson/common.json");
      this.site = response.default
    let html = document.getElementsByTagName('html')[0]
  let width = window.innerWidth < 980 ? window.innerWidth : 1200
  /* 640 100  320 50 */
  let fontSize = width / 750 * 100
  if (window.innerWidth > 980) {
    html.style.fontSize = 14 + 'px'
  }else {
    html.style.fontSize = fontSize + 'px'
  }

  window.innerObj = {
    innerWidth: window.innerWidth
  }
  window.onresize = function () {
    let html = document.getElementsByTagName('html')[0]
    let width = window.innerWidth < 980 ? window.innerWidth : 980
    window.innerObj.innerWidth = window.innerWidth
    /* 640 100  320 50 */
    let fontSize = width / 750 * 100
    if (window.innerWidth > 980) {
      html.style.fontSize = 14 + 'px'
    }else {
      html.style.fontSize = fontSize + 'px'
    }
  }
    }
  },
  async created() {
    this.init()
  }
};
</script>
<style lang="scss">
  *{
    margin: 0;
    padding: 0;
  }
  a{
  text-decoration: none;
}
:root {
  /* 主题颜色 */
  --theme-primary: linear-gradient(135deg, #32b4ff 0%, #d642ff 100%);
  /* 输入框聚焦 */
  --theme-focus: #419EFF;
  /* 输入框错误 */
  --theme-focus-err: #ea3042;
  /* 按钮颜色 */
  --theme-btn-color: linear-gradient(135deg, #32b4ff 0%, #d642ff 100%);
  /* 按钮点击 */
  --theme-btn-active: #775cdf;
  /* 按钮悬停 */
  --theme-btn-hover: linear-gradient(135deg, #32b4ff 0%, #d642ff 100%);
  /* 下拉菜单悬停 */
  --theme-dropdown-hover: #EEF6FA;
  /* 普通字体颜色 */
  --theme-font-normal: #d47f39;
  /* 主页主题色1 */
  --theme-home-primary: #d47f39;
  /* 主页主题色2 */
  --theme-home-primary1: linear-gradient(315deg, #ebfbff 0%, #ffebfc 100%);
  /* 编辑页主题色 */
  --theme-camera-primary: #f1f3f5;
  /* 标签页颜色 */
  --theme-camera-tab-active-color: rgb(231, 235, 238);
  /* 活跃字体颜色 */
  --theme-camera-tab-active-text: #868e96;
  /* 非活跃字体色 */
  --theme-camera-tab-normal-text: rgba(134, 142, 150, 0.5);

  /*  暗色背景*/
  --them-bg-default: #ffffff;
  --them-card-bg-default: #ffffff;
  --them-card-border-default: #e7e3fb;
  --them-card-p-default: #82808e;
  /* 确认弹窗按钮主要颜色 */
  --theme-dialog-confirm-primary: #e6563d;
}
</style>
